<template>
  <div class="indexHero">
    <div class="container">
      <div class="indexHero__wrapper">
        <div class="indexHero__left">
          <h1>ITide,<br> <mark>poď aj ty!</mark>
          </h1>
          <ul>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Študuj zadarmo, online a podľa tvojich časových možností
            </li>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Využi nášho kariérneho poradenstva/ Nechaj si poradiť s kariérou a zisti, čo ďalej
            </li>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Dostávaj pracovné ponuky zodpovedajúce tvojim znalostiam a študijným pokrokom
            </li>
          </ul>
          <BaseButton
              v-if="!loggedIn"
              :is-link="true"
              :href="'/registration'"
          >
            Registrácia zdarma
          </BaseButton>
          <BaseButton
              v-else
              :is-link="true"
              :href="'/courses'"
          >
            Chcem študovať
          </BaseButton>
        </div>
        <div class="indexHero__right">
          <div class="indexHero__videoBox-video">
          <button type="button" aria-label="Přehrát video" @click.prevent="modalActive = true">
            <picture>
              <source
                  type="image/webp"
                  srcset="../assets/images/indexHero/indexHeroVideo/video.webp"
              >
              <img
                  alt=""
                  src="../assets/images/indexHero/indexHeroVideo/video.png"
                  class="indexHero__videoBox-video__bg"
                  width="387"
                  height="217"
                  loading="lazy"
                  decoding="async"
              >
            </picture>
            <img
                src="../assets/images/indexHero/indexHeroVideo/play.svg"
                alt=""
                class="indexHero__videoBox-video__play"
                width="76"
                height="76"
                loading="lazy"
                decoding="async"
            >
          </button>
        </div>

        </div>
      </div>
    </div>
  </div>
  <BaseModal
      :id="5"
      :is-active="modalActive"
      :size="800"
      @dtc-modal-close="modalActive = false">
    <BaseVideoPlayer
        video-url="https://hls.goodcall.eu/ITJede_video/ITJede_video.m3u8"
        poster="../assets/images/indexHero/indexHeroVideo/video.webp"
        :autoplay="true"
    />
  </BaseModal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseModal from "@/components/BaseModal";
import BaseVideoPlayer from "@/components/BaseVideoPlayer";
import IndexHeroCarousel from "@/components/IndexHeroCarousel";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
    BaseModal,
    BaseVideoPlayer,
    IndexHeroCarousel,
  },
  data() {
    return {
      modalActive: false,
    }
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>