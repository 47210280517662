<template>
  <div class="indexCollapse">
    <BaseCollapse
        v-for="(item, index) in data"
        :key="item"
        :id="index"
        :isActive="activeItem === index"
        :type="'whiteShadow'"
        @baseCollapseToggle="handleClick(index)"
    >
      <template v-slot:title>{{ item.title }}</template>
      <template v-slot:default>
        <div v-html="item.text"></div>
      </template>
    </BaseCollapse>
  </div>
</template>
<script>
import BaseCollapse from "@/components/baseCollapse";

export default {
  components: {BaseCollapse},
  data() {
    return {
      activeItem: null,
      data: [
        {
          title: 'Čo je ITide.sk?',
          text: '<p>ITide.sk je vzdelávacia platforma, ktorá ti umožní rozšíriť si digitálne zručnosti, pripraví ťa na oficiálnu Microsoft certifikáciu, zvýši tvoju hodnotu na pracovnom trhu a pomôže ti nájsť lepšiu prácu!</p><p>Vďaka úzkej spolupráci priamo s Microsoftom a jeho partnermi na slovenskom trhu sme schopní našim úspešným absolventom poskytnúť široké spektrum kariérnych príležitostí. Akonáhle absolvuješ aspoň základný úvodný kurz – technológie a ich budúcnosť, obratom s tebou prekonzultujeme tvoj kariérny postup a odporučíme ďalšie kroky.</p>',
        },
        {
          title: 'Komu je platforma určená?',
          text: '<p>Každému, kto chce urobiť krok vpred vo svojej kariére! Naša vzdelávacia a kariérna platforma je určená ako pre úplných začiatočníkov, ktorí chcú získať základné znalosti a prehľad o IT svete, tak pre skúsenejších študentov so záujem o svet technológií a prípravu na oficiálne certifikácie.</p><p>ITide.sk ti umožní zvýšiť svoju hodnotu na pracovnom trhu a získať kariérne príležitosti vo svete informačných technológií. Na začiatok nepotrebuješ nič viac, len chuť a čas vzdelávať sa. Preto už neváhaj ani minútu – lepšia práca čaká aj na teba, pretože IT ide. Tak poď aj ty!</p>',
        },
        {
          title: 'Ako štúdium prebieha?',
          text: '<p>Akonáhle sa zaregistruješ, získaš automatický prístup k úvodnému kurzu. Ten ťa prevedie prostredím Microsoft technológií, systémov a témou týkajúcou sa digitalizácie firiem. Potom sa stačí rozhodnúť, ktorým smerom sa chceš uberať, následne ti sprístupnime príslušné kurzy zamerané na tvoju špecializáciu. Vďaka online forme môžeš študovať kedykoľvek a odkiaľkoľvek z pohodlia domova.</p>',
        },
        {
          title: 'Hľadáš prácu?',
          text: '<p>Akonáhle prejdeš úvodným kurzom, máš kedykoľvek možnosť požiadať o prepojenie s jedným z našich kariérnych konzultantov. Následne, ti na základe tvojich preferencií a skúseností ponúkneme konkrétne pracovné príležitosti a taktiež tvoj profil zašleme potenciálnym zamestnávateľom.</p>',
        },
      ]
    }
  },
  methods: {
    handleClick(id) {
      if (this.activeItem === id) {
        this.activeItem = null
      } else {
        this.activeItem = id;
      }
    }
  }
}
</script>