<template>
  <div class="indexAbout">
    <div class="container container--xs">
      <div class="indexAbout__header">
        <h2 class="h1 indexAbout__header-title">
          Aké kurzy Ťa čakajú?
        </h2>
      </div>
      <div class="indexAbout__body">
        <p>
          Vyberať môžeš zo širokej ponuky kurzov zameraných na <mark>cloudové technológie</mark>, ako sú produkty z rodiny <mark>Microsoft 365</mark>, <mark>Azure</mark> alebo <mark>Power Platform</mark>. Dozvieš sa o základných pojmoch v úvode do <mark>AI a strojového učenia</mark>. V ponuke nájdeš aj oblasť <mark>zabezpečenia dát a cybersecurity</mark>. A pokiaľ chceš preniknúť do sveta <mark>CRM a ERP systémov</mark>, tak aj z tejto oblasti tu nájdeš zaujímavý obsah.
        </p>
        <p>
          Pokiaľ nevieš, kde začať, odporúčame využiť naše <mark>študijné programy</mark>, ktoré ťa postupne vykonajú jednotlivými technológiami. Tieto programy sú navrhnuté tak, aby si sa mohol(a) učiť v logickom slede a nič dôležité ti neuniklo.
        </p>
      </div>
      <div class="indexAbout__footer">
        <BaseButton
            v-if="!loggedIn"
            :is-link="true"
            :href="'/registration'"
        >
          Registrácia zdarma
        </BaseButton>
        <BaseButton
            v-else
            :is-link="true"
            :href="'/courses'"
        >
          Chcem študovať
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>