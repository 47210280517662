<template>
  <HighlightedSection :title="translation('consulting_form-title')" :text="translation('consulting_form-text')" :size="'xxs'"
    :color="color">
    <template v-slot:after>
      <form @submit="onSubmit" class="container container--xxs">
        <div class="formGrid">
          <BaseAlertList :alerts="formAlerts" class="mb-4" />

          <!-- First name -->
          <FormInput
            :label="translation('global_input-firstname-label')"
            :required="true"
            :placeholder="translation('global_input-firstname-placeholder')"
            :uuid="`firstname`"
            :error="firstnameError"
            v-model="firstname"
          />

          <!-- Last name -->
          <FormInput
            :label="translation('global_input-lastname-label')"
            :required="true"
            :placeholder="translation('global_input-lastname-placeholder')"
            :uuid="`lastname`"
            :error="lastnameError"
            v-model="lastname"
          />

          <!-- Email -->
          <FormInput
            :label="translation('global_input-email-label')"
            :required="true"
            :type="'email'"
            :placeholder="translation('global_input-email-placeholder')"
            :uuid="`email`"
            :error="emailError"
            v-model="email"
          />

          <!-- Phone -->
          <FormInput
            :label="translation('global_input-phone-label')"
            :type="'tel'"
            :placeholder="translation('global_input-phone-placeholder')"
            :uuid="`phone`"
            :error="phoneError"
            v-model="phone"
          />

          <!-- Button -->
          <div class="formGroup--button">
            <BaseButton type="submit" :full-width="true" :isDisabled="isSubmitting">
              {{ translation('consulting_form-submit_button') }}
            </BaseButton>
          </div>
        </div>
      </form>
    </template>
  </HighlightedSection>
</template>

<script>
import BaseAlertList from "@/components/BaseAlertList";
import BaseButton from "@/components/BaseButton";
import FormInput from "@/components/FormInput";
import HighlightedSection from "@/components/HighlightedSection";
import { codeBooksHelpers } from "@/helpers/codeBooks.helper";
import { generalService } from "@/services/general.service";
import { useField, useForm } from 'vee-validate';
import { ref, computed, watchEffect } from "vue";
import { useStore } from 'vuex';
import * as yup from 'yup';

export default {
  components: {
    FormInput,
    HighlightedSection,
    BaseButton,
    BaseAlertList
  },
  props: {
    color: {
      type: String,
      default: null
    }
  },
  setup() {
    const store = useStore(); // Vuex store init
    const userData = computed(() => store.state.user.userData);

    // Form level alert variable
    let formAlerts = ref(null);

    // Import translations
    const { translation } = codeBooksHelpers();

    // Validator configuration
    const validationSchema = yup.object({
      firstname: yup
        .string()
        .required(() => translation('global_input-firstname-error'))
        .min(2, () => translation('global_validation-min_length', { number: 2 }))
        .max(80, () => translation('global_validation-max_length', { number: 80 })),
      lastname: yup
        .string()
        .required(() => translation('global_input-lastname-error'))
        .min(2, () => translation('global_validation-min_length', { number: 2 }))
        .max(30, () => translation('global_validation-max_length', { number: 30 })),
      email: yup
        .string()
        .required(() => translation('global_input-email-error'))
        .email(() => translation('global_validation-email_format'))
        .max(255, () => translation('global_validation-max_length', { number: 255 })),
      phone: yup
        .string()
        .max(30, () => translation('global_validation-max_length', { number: 30 }))
        .required(() => translation('global_input-phone-error')),
    });

    // Creates a vee-validate form context
    const { handleSubmit, isSubmitting, resetForm, setFieldValue } = useForm({
      validationSchema,
      initialValues: {
        subject: 'Žiadosť o konzultáciu',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      }
    });

    // Sledování `userData` a nastavování hodnot formuláře, jakmile jsou dostupné
    watchEffect(() => {
      if (userData.value) {
        setFieldValue('firstname', userData.value.firstname || '');
        setFieldValue('lastname', userData.value.lastname || '');
        setFieldValue('email', userData.value.email || '');
        setFieldValue('phone', userData.value.phone || '');
      }
    });

    // Create form fields with their validation state
    const { value: subject, errorMessage: subjectError } = useField('subject');
    const { value: firstname, errorMessage: firstnameError } = useField('firstname');
    const { value: lastname, errorMessage: lastnameError } = useField('lastname');
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: phone, errorMessage: phoneError } = useField('phone');

    // Submit handler
    const onSubmit = handleSubmit(async values => {
      try {
        // Create the message from form input values
        const message = `
          First Name: ${values.firstname}<br>
          Last Name: ${values.lastname}<br>
          Email: ${values.email}<br>
          Phone: ${values.phone}
        `;

        // Send data to the API
        await generalService.sendConsultForm(values.subject, message, values.email);

        // Success message
        formAlerts.value = [{ 'message': translation('contact_form-alert-success'), type: "success" }];

        // Reset form
        resetForm();
      } catch (err) {
        formAlerts.value = [{ 'message': translation('global_alert-general'), type: "danger" }];
      }
    });

    return {
      subject,
      subjectError,
      firstname,
      firstnameError,
      lastname,
      lastnameError,
      email,
      emailError,
      phone,
      phoneError,
      onSubmit,
      isSubmitting,
      formAlerts,
      translation
    };
  }
};
</script>
