<template>
    <div :class="{'instructors': true, 'instructors--dark': dark}">
        <h2>Náš team</h2>
        <InstructorsLayout :items-count="3">
          <InstructorCard
            image="pavel-vanura.jpeg"
            name="Pavel Vaňura"
            position="Talent Acquisition Advisor"
            text="Zoznámte sa s Pavlom, ktorý s vami prediskutuje vaše kariérne možnosti. Pavel pôvodne študoval IT, ale uvedomil si, že ho viac naplní práca a komunikácia s ľuďmi a pocit, že im môže pomôcť nájsť si prácu. Po dlhoročných skúsenostiach v oblasti náboru a HR sa tak vrhol do spolupráce s ITjede, kde môže spojiť svoju doterajšiu prax a radiť ľuďom, na ktorých mieste bol pred 12 rokmi. Nebojte sa na neho obrátiť s čímkoľvek. Rád vám poradí. Vo voľnom čase Pavel rád cestuje, rád sedí s priateľmi alebo relaxuje pri hre."
            socialUrl="https://www.linkedin.com/in/pavel-va%C5%88ura-8734b0237/"
            :light="dark"
          />
          <InstructorCard
            image="ondrej-hanus.jpg"
            name="Ondřej Hanuš"
            position="Chief Digital Officer"
            text="Ondra je nadšenec digitálnych zručností a má dlhoročné skúsenosti v IT spoločnostiach. Je spoluautorom a zakladateľom projektu ITjede, ktorý aktívne posúva vpred, hľadá nové spôsoby, ako vám pomôcť pri štúdiu alebo hľadaní nových kariérnych príležitostí. Ondra počas svojej kariéry prešiel z programátora na vedúce pozície, takže má veľmi dobré predpoklady na to, aby vám pomohol nasmerovať vašu kariéru a odovzdávať svoje skúsenosti. Okrem projektu ITjede je v GoodCall zodpovedný za digitálnu stratégiu a inovácie. A okrem iného je milovníkom dobrej kávy a gastronómie :-)"
            socialUrl="https://www.linkedin.com/in/ohanus/"
            :light="dark"
          />
          <InstructorCard
            image="adam-libra.jpg"
            name="Adam Libra"
            position="Vývojář platformy, Lektor"
            text="Adam je najmladším členom nášho tímu. V oblasti IT pôsobí od svojich 16 rokov, začínal v študentskom programe od spoločnosti Microsoft. Následne nastúpil do spoločnosti Microsoft ako zamestnanec. Adam je programátor s obchodným duchom a cloudové technológie sú jeho koníčkom. V ITje je nielen moderátorom a lektorom, ale aj hlavným vývojárom celej platformy. Keď nie je ponorený do jednotiek a núl, rád jazdí na bicykli a dobre sa naje."
            socialUrl="https://www.linkedin.com/in/adamlibra/"
            :light="dark"
          />
        </InstructorsLayout>
    </div>
</template>
<script>
    import InstructorCard from "@/components/InstructorCard.vue";
    import InstructorsLayout from "@/components/InstructorsLayout.vue";

    export default {
        props: {
            dark: {
                type: Boolean,
                default: false
            },
        },
        components: {
          InstructorCard,
          InstructorsLayout,
        }
    }
</script>