<template>
  <div class="indexJobs">
    <div class="container container--md">
      <h2 class="h1 indexJobs__title mb-3">
        Voľné IT&nbsp;pozície
      </h2>
      <p>
        Už čoskoro až 100 voľných pracovných miest! Zvez sa na vlne popularity informačných technológií! My&nbsp;ti&nbsp;zdarma poskytneme rekvalifikáciu a ešte ti nájdeme novú prácu!
      </p>
      <BaseButton
          :is-link="true"
          :href="'https://jobs.datacruit.com/nabidky-prace/itjede/'"
          target="_blank"
      >
        Ukázať pozície
      </BaseButton>
    </div>
    <img
        src="../assets/images/particles/briefcase.svg"
        alt=""
        class="indexJobs__particle"
        width="430"
        height="401"
        loading="lazy"
    >
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";

export default {
  components: {BaseButton}
}
</script>
